import { Issue, NotFoundError, ValidationError } from "src/errors";

import ApiResourceCollection from "src/models/ApiResourceCollection";
import EmployerExemptionsApplication from "src/models/EmployerExemptionsApplication";
import EmployerExemtionsApi from "src/api/EmployerExemptionsApi";
import { ErrorsLogic } from "./useErrorsLogic";
import PaginationMeta from "src/models/PaginationMeta";
import { PortalFlow } from "./usePortalFlow";
import { UsersLogic } from "./useUsersLogic";
import getRelevantIssues from "src/utils/getRelevantIssues";
import routes from "src/routes";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const useEmployerExemptionApplicationsLogic = ({
  errorsLogic,
  portalFlow,
}: // usersLogic,
{
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
  usersLogic: UsersLogic;
}) => {
  // State representing the collection of applications for the current user.
  // Initialize to empty collection, but will eventually store the applications
  // state as API calls are made to fetch the user's applications and/or create
  // new applications
  const {
    collection: employerExemptionsApplication,
    addItem: addEmployerExemptionsApplication,
    updateItem: setEmployerExemptionsApplication,
  } = useCollectionState(
    new ApiResourceCollection<EmployerExemptionsApplication>(
      "employer_exemption_application_id"
    )
  );

  // Tracks loading state of employerExemptions when calling loadPage()
  const [isLoadingExemption, setIsLoadingExemption] = useState<boolean>();

  // Pagination info associated with the current collection of employerExemptions
  const [paginationMeta, setPaginationMeta] = useState<
    PaginationMeta | { [key: string]: never }
  >({});

  const applicationsApi = new EmployerExemtionsApi();

  // Cache the validation warnings associated with each employerExemption. Primarily
  // used for controlling the status of Checklist steps.
  const [warningsLists, setWarningsLists] = useState<{
    [employer_exemption_application_id: string]: Issue[];
  }>({});

  /**
   * Store warnings for a specific employerExemption
   */
  const setEmployerExemptionWarnings = (
    employer_exemption_application_id: string,
    warnings: Issue[]
  ) => {
    setWarningsLists((prevWarningsList) => {
      return {
        ...prevWarningsList,
        [employer_exemption_application_id]: warnings,
      };
    });
  };

  /**
   * Check if a employerExemption and its warnings have been loaded. This helps
   * our withBenefitsApplication higher-order component accurately display a loading state.
   */
  const hasLoadedEmployerExemptionsApplicationAndWarnings = (
    employer_exemption_application_id: string
  ) => {
    // !! so we always return a Boolean
    return !!(
      warningsLists.hasOwnProperty(employer_exemption_application_id) &&
      employerExemptionsApplication.getItem(employer_exemption_application_id)
    );
  };

  /**
   * Reset the state to force applications to be refetched the
   * next time loadPage is called.
   */
  const invalidateApplicationsCache = () => {
    setIsLoadingExemption(undefined);
    setPaginationMeta({});
  };

  /**
   * Load a single employerExemption
   */
  const load = async (employer_exemption_application_id: string) => {
    // Skip API request if we already have the employerExemption AND its validation warnings.
    // It's important we load the employerExemption if warnings haven't been fetched yet,
    // since the Checklist needs those to be present in order to accurately
    // determine what steps are completed.
    if (
      hasLoadedEmployerExemptionsApplicationAndWarnings(
        employer_exemption_application_id
      )
    ) {
      return;
    }
    errorsLogic.clearErrors();

    try {
      const { exemptionRequest, warnings } =
        await applicationsApi.getExemptionApplication(
          employer_exemption_application_id
        );

      if (
        employerExemptionsApplication.getItem(employer_exemption_application_id)
      ) {
        setEmployerExemptionsApplication(exemptionRequest);
      } else {
        addEmployerExemptionsApplication(exemptionRequest);
      }

      setEmployerExemptionWarnings(employer_exemption_application_id, warnings);
    } catch (error) {
      if (error instanceof NotFoundError) {
        portalFlow.goTo(routes.employers.employerExemptions.progress);
        return;
      }

      errorsLogic.catchError(error);
    }
  };

  /**
   * Update the employerExemption in the API and set application errors if any
   * @param patchData - subset of employerExemption data that will be updated, and
   * used as the list of fields to filter validation warnings by
   */
  const update = async (
    employer_exemption_application_id: string,
    patchData: Partial<EmployerExemptionsApplication>
  ) => {
    errorsLogic.clearErrors();

    try {
      const { exemptionRequest, warnings } =
        await applicationsApi.updateExemptionApplication(
          employer_exemption_application_id,
          patchData
        );

      const issues = getRelevantIssues(warnings, [portalFlow.page]);

      setEmployerExemptionsApplication(exemptionRequest);
      setEmployerExemptionWarnings(employer_exemption_application_id, warnings);

      // If there were only validation warnings, then throw *after*
      // the employerExemption has been updated in our state, so our local employerExemption
      // state remains consistent with the employerExemption state stored in the API,
      // which still received the updates in the request. This is important
      // for situations like leave periods, where the API passes us back
      // a leave_period_id field for making subsequent updates.
      if (issues.length) {
        throw new ValidationError(issues);
      }

      const params = {
        employerExemption_id:
          exemptionRequest.employer_exemption_application_id,
      };
      portalFlow.goToNextPage({ exemptionRequest, warnings }, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Update the employerExemption in the API, ignoring any errors that occur
   *
   * @param patchData - subset of employerExemption data that will be updated
   * @param exemptionRequest - the current employerExemption data from benefits application
   */
  const updateAndIgnoreErrors = async (
    employer_exemption_application_id: string,
    patchData: Partial<EmployerExemptionsApplication>,
    exemptionRequest: EmployerExemptionsApplication
  ) => {
    errorsLogic.clearErrors();

    try {
      const { exemptionRequest: updatedEmployerExemption, warnings } =
        await applicationsApi.updateExemptionApplication(
          employer_exemption_application_id,
          patchData
        );

      // Update the employerExemption in the context
      setEmployerExemptionsApplication(updatedEmployerExemption);
      setEmployerExemptionWarnings(employer_exemption_application_id, warnings);

      const params = {
        employerExemption_id:
          updatedEmployerExemption.employer_exemption_application_id,
      };
      portalFlow.goToNextPage(
        { exemptionRequest: updatedEmployerExemption, warnings },
        params
      );
    } catch (error) {
      errorsLogic.catchErrorNoShow(error);

      // Always navigate to the next page, even if an error occurs.
      const fallbackParams = {
        employerExemption_id: employer_exemption_application_id,
      };
      portalFlow.goToNextPage({ exemptionRequest }, fallbackParams);
    }
  };

  // this  section is for use when we have the api functions in place

  /**
   * Complete the employerExemption in the API
   */
  // const complete = async (
  //   applicationId: string,
  //   certificateDocumentDeferred: boolean = false
  // ) => {
  //   errorsLogic.clearErrors();

  //   try {
  //     const { employerExemption } =
  //       await applicationsApi.completeEmployerExemption(
  //         applicationId,
  //         certificateDocumentDeferred
  //       );

  //     setEmployerExemptionsApplication(employerExemption);
  //     const context = { employerExemption };
  //     const params = {
  //       employerExemption_id:
  //         employerExemption.employer_exemption_application_id,
  //     };
  //     portalFlow.goToNextPage(context, params);
  //   } catch (error) {
  //     errorsLogic.catchError(error);
  //   }
  // };

  /**
   * Create the employerExemption in the API. Handles errors and routing.
   */
  // const create = async () => {
  //   errorsLogic.clearErrors();

  //   try {
  //     const { exemptionRequest } =
  //       await applicationsApi.createEmployerExemption();

  //     let userProfileHasUsableDataForApplicationResponse;
  //     if (usersLogic.user) {
  //       try {
  //         userProfileHasUsableDataForApplicationResponse =
  //           await userProfileHasUsableDataForApplication(
  //             usersLogic.user.user_id
  //           );
  //       } catch (error) {
  //         errorsLogic.catchErrorNoShow(error);
  //       }
  //     }

  //     // Reset so that this newly created employerExemption is listed
  //     invalidateApplicationsCache();

  //     const context = {
  //       employerExemption,
  //       userProfileHasUsableDataForApplication:
  //         userProfileHasUsableDataForApplicationResponse,
  //     };
  //     const params = {
  //       employerExemption_id:
  //         employerExemption.employer_exemption_application_id,
  //     };
  //     portalFlow.goToPageFor("CREATE_employerExemption", context, params);
  //   } catch (error) {
  //     errorsLogic.catchError(error);
  //   }
  // };

  /**
   * Submit the employerExemption in the API and set application errors if any
   */
  // const submit = async (employer_exemption_application_id: string) => {
  //   errorsLogic.clearErrors();

  //   try {
  //     const { employerExemption } =
  //       await applicationsApi.submitemployerExemption(
  //         employer_exemption_application_id
  //       );

  //     let userProfileCheckForUpdates;
  //     if (usersLogic.user) {
  //       try {
  //         userProfileCheckForUpdates =
  //           await checkUserProfileForUpdatesFromApplication(
  //             usersLogic.user.user_id,
  //             employerExemption
  //           );
  //       } catch (error) {
  //         errorsLogic.catchErrorNoShow(error);
  //       }
  //     }

  //     setEmployerExemptionsApplication(employerExemption);
  //     if (employerExemption.split_into_employer_exemption_application_id) {
  //       // Force a refetch so the second of the split applications gets displayed
  //       invalidateApplicationsCache();
  //     }

  //     const context = { employerExemption, userProfileCheckForUpdates };

  //     const applicationWasCreatedInFineos = employerExemption.isInManualReview
  //       ? { "part-one-in-review": "true" }
  //       : { "part-one-submitted": "true" };
  //     const params = {
  //       employerExemption_id:
  //         employerExemption.employer_exemption_application_id,
  //       ...applicationWasCreatedInFineos,
  //     };
  //     portalFlow.goToNextPage(context, params);
  //   } catch (error) {
  //     errorsLogic.catchError(error);
  //   }
  // };

  return {
    employerExemptionsApplication,
    // complete,
    // create,
    hasLoadedEmployerExemptionsApplicationAndWarnings,
    invalidateApplicationsCache,
    isLoadingExemption,
    load,
    paginationMeta,
    update,
    updateAndIgnoreErrors,
    // submit,
    warningsLists,
  };
};
export default useEmployerExemptionApplicationsLogic;
