import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "src/hoc/withBenefitsApplication";

import ApplicationPagePrepopulated from "src/features/universal-profiles/application-page-prepopulated";
import InputDate from "src/components/core/InputDate";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { isFeatureEnabled } from "src/services/featureFlags";
import { pick } from "lodash";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

export const fields = ["claim.date_of_birth"];

export const DateOfBirth = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const usedProfileData =
    isFeatureEnabled("enableUniversalProfileIDV") &&
    !!claim.fields_to_use_from_user_profile?.includes("dateOfBirth");

  const { formState, updateFields } = useFormState(pick(props, fields).claim);

  const handleSave = () =>
    appLogic.benefitsApplications.update(claim.application_id, formState);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  return (
    <QuestionPage
      title={t("pages.claimsDateOfBirth.title")}
      onSave={handleSave}
      alertBanner={usedProfileData && <ApplicationPagePrepopulated />}
    >
      <InputDate
        {...getFunctionalInputProps("date_of_birth")}
        label={t("pages.claimsDateOfBirth.sectionLabel")}
        example={t("components.form.dateInputExample")}
        dayLabel={t("components.form.dateInputDayLabel")}
        monthLabel={t("components.form.dateInputMonthLabel")}
        yearLabel={t("components.form.dateInputYearLabel")}
      />
    </QuestionPage>
  );
};

export default withBenefitsApplication(DateOfBirth);
