import BaseApi from "./BaseApi";
import { UserLeaveAdministrator } from "src/models/User";
import routes from "src/routes";

export type LeaveAdminsSearchRequest =
  | { employer_id: string }
  | { user_id: string };

export interface LeaveAdminsRemoveRequest {
  user_leave_administrator_id: string;
}

export type LeaveAdminsAddRequest = { employer_id: string } & {
  email_address: string;
};

export default class LeaveAdminsApi extends BaseApi {
  get basePath() {
    return routes.api.leaveAdmins;
  }

  get namespace() {
    return "leaveAdmins";
  }

  add = async (request: LeaveAdminsAddRequest) => {
    await this.request<UserLeaveAdministrator[]>("POST", "add", request);
  };

  list = async (request: LeaveAdminsSearchRequest) => {
    const { data } = await this.request<UserLeaveAdministrator[]>(
      "POST",
      "search",
      request
    );

    return {
      leaveAdmins: data.map(
        (leaveAdmin) => new UserLeaveAdministrator(leaveAdmin)
      ),
    };
  };

  remove = async ({
    user_leave_administrator_id,
  }: LeaveAdminsRemoveRequest) => {
    await this.request<UserLeaveAdministrator[]>(
      "POST",
      `${user_leave_administrator_id}/deactivate`
    );
  };
}
