import { AddressFormatResponse, AddressResponse } from "src/models/Experian";
import { Issue, NotFoundError, ValidationError } from "src/errors";
import User, {
  UserProfileCheckForUpdatesRequest,
  UserProfileCheckForUpdatesResponse,
  UserProfileHasUsableDataForApplicationResponse,
  UserProfileUpdate,
} from "src/models/User";
import { useMemo, useState } from "react";

import Address from "src/models/Address";
import ApiResourceCollection from "src/models/ApiResourceCollection";
import BenefitsApplication from "src/models/BenefitsApplication";
import BenefitsApplicationsApi from "src/api/BenefitsApplicationsApi";
import { ErrorsLogic } from "./useErrorsLogic";
import ExperianApi from "src/api/ExperianAPI";
import { NullableQueryParams } from "src/utils/routeWithParams";
import PaginationMeta from "src/models/PaginationMeta";
import PaymentPreference from "src/models/PaymentPreference";
import { PortalFlow } from "./usePortalFlow";
import TaxWithholdingPreference from "src/models/TaxWithholdingPreference";
import UsersApi from "src/api/UsersApi";
import { UsersLogic } from "./useUsersLogic";
import formatAddress from "src/utils/formatAddress";
import getRelevantIssues from "src/utils/getRelevantIssues";
import routes from "src/routes";
import useCollectionState from "./useCollectionState";

const useBenefitsApplicationsLogic = ({
  errorsLogic,
  portalFlow,
  usersLogic,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
  usersLogic: UsersLogic;
}) => {
  // State representing the collection of applications for the current user.
  // Initialize to empty collection, but will eventually store the applications
  // state as API calls are made to fetch the user's applications and/or create
  // new applications
  const {
    collection: benefitsApplications,
    addItem: addBenefitsApplication,
    updateItem: setBenefitsApplication,
    setCollection: setBenefitsApplications,
  } = useCollectionState(
    new ApiResourceCollection<BenefitsApplication>("application_id")
  );

  // Tracks loading state of claims when calling loadPage()
  const [isLoadingClaims, setIsLoadingClaims] = useState<boolean>();

  // Pagination info associated with the current collection of claims
  const [paginationMeta, setPaginationMeta] = useState<
    PaginationMeta | { [key: string]: never }
  >({});

  const applicationsApi = new BenefitsApplicationsApi();
  const experianApi = new ExperianApi();
  const usersApi = useMemo(() => new UsersApi(), []);

  // Cache the validation warnings associated with each claim. Primarily
  // used for controlling the status of Checklist steps.
  const [warningsLists, setWarningsLists] = useState<{
    [application_id: string]: Issue[];
  }>({});

  /**
   * Store warnings for a specific claim
   */
  const setClaimWarnings = (application_id: string, warnings: Issue[]) => {
    setWarningsLists((prevWarningsList) => {
      return {
        ...prevWarningsList,
        [application_id]: warnings,
      };
    });
  };

  /**
   * Check if a claim and its warnings have been loaded. This helps
   * our withBenefitsApplication higher-order component accurately display a loading state.
   */
  const hasLoadedBenefitsApplicationAndWarnings = (application_id: string) => {
    // !! so we always return a Boolean
    return !!(
      warningsLists.hasOwnProperty(application_id) &&
      benefitsApplications.getItem(application_id)
    );
  };

  /**
   * Reset the state to force applications to be refetched the
   * next time loadPage is called.
   */
  const invalidateApplicationsCache = () => {
    setIsLoadingClaims(undefined);
    setPaginationMeta({});
  };

  /**
   * Load a single claim
   */
  const load = async (application_id: string) => {
    // Skip API request if we already have the claim AND its validation warnings.
    // It's important we load the claim if warnings haven't been fetched yet,
    // since the Checklist needs those to be present in order to accurately
    // determine what steps are completed.
    if (hasLoadedBenefitsApplicationAndWarnings(application_id)) return;

    errorsLogic.clearErrors();

    try {
      const { claim, warnings } = await applicationsApi.getClaim(
        application_id
      );

      if (benefitsApplications.getItem(application_id)) {
        setBenefitsApplication(claim);
      } else {
        addBenefitsApplication(claim);
      }

      setClaimWarnings(application_id, warnings);
    } catch (error) {
      if (error instanceof NotFoundError) {
        portalFlow.goTo(routes.applications.index);
        return;
      }

      errorsLogic.catchError(error);
    }
  };

  /**
   * Load a page of claims for the authenticated user
   * @param [pageOffset] - Page number to load
   */
  const loadPage = async (pageOffset: number | string = 1) => {
    if (isLoadingClaims) return;
    if (paginationMeta.page_offset === Number(pageOffset)) return;

    setIsLoadingClaims(true);
    errorsLogic.clearErrors();

    try {
      const { claims, paginationMeta } = await applicationsApi.getClaims(
        pageOffset
      );
      setBenefitsApplications(claims);
      setPaginationMeta(paginationMeta);
    } catch (error) {
      errorsLogic.catchError(error);
      // to avoid infinite loop when errors are encountered:
      setPaginationMeta(<PaginationMeta>{ page_offset: Number(pageOffset) });
    } finally {
      setIsLoadingClaims(false);
    }
  };

  /**
   * Update the claim in the API and set application errors if any
   * @param patchData - subset of claim data that will be updated, and
   * used as the list of fields to filter validation warnings by
   */
  const update = async (
    application_id: string,
    patchData: Partial<BenefitsApplication>
  ) => {
    errorsLogic.clearErrors();

    try {
      const { claim, warnings } = await applicationsApi.updateClaim(
        application_id,
        patchData
      );

      const issues = getRelevantIssues(warnings, [portalFlow.page]);
      addExtraIssueInfo(claim, issues);

      setBenefitsApplication(claim);
      setClaimWarnings(application_id, warnings);

      // If there were only validation warnings, then throw *after*
      // the claim has been updated in our state, so our local claim
      // state remains consistent with the claim state stored in the API,
      // which still received the updates in the request. This is important
      // for situations like leave periods, where the API passes us back
      // a leave_period_id field for making subsequent updates.
      if (issues.length) {
        throw new ValidationError(issues);
      }

      const params = { claim_id: claim.application_id };
      portalFlow.goToNextPage({ claim, warnings }, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Add extra info to the errors for an application. This is done
   * by populating the extra field of an Issue. This allows us to add
   * additional info to the error messages displayed to the user.
   * @param claim The BenefitsApplication the issues are for
   * @param issues Array of issues
   */
  const addExtraIssueInfo = (claim: BenefitsApplication, issues: Issue[]) => {
    issues.forEach((issue) => {
      if (issue.field === "hours_worked_per_week_all_employers") {
        if (issue.type === "minimum") {
          issue.extra = {
            hours_worked_per_week:
              claim.hours_worked_per_week !== null
                ? claim.hours_worked_per_week
                : 0,
            employer_fein:
              claim.employer_fein !== null ? claim.employer_fein : "",
          };
        }
      }
    });
  };

  const validateAddressFields = (
    residential_address: Address,
    mailing_address: Address,
    has_mailing_address: boolean
  ): Issue[] => {
    const issues: Issue[] = [];
    errorsLogic.clearErrors();

    const validateFields = (address: Address, addressType: string) => {
      const fieldsToValidate = [
        { field: address.line_1, name: "line_1" },
        { field: address.city, name: "city" },
        { field: address.state, name: "state" },
        { field: address.zip, name: "zip" },
      ];

      fieldsToValidate.forEach(({ field, name }) => {
        if (!field) {
          issues.push({
            field: `${addressType}.${name}`,
            message: `${addressType}.${name} is required`,
            namespace: "applications",
            type: "required",
            rule: "required",
          });
        }
      });
    };

    try {
      // Validate residential address
      validateFields(residential_address, "residential_address");

      // Validate mailing address if present
      if (has_mailing_address) {
        validateFields(mailing_address, "mailing_address");
      }

      if (issues.length) {
        throw new ValidationError(issues);
      }
    } catch (error) {
      errorsLogic.catchError(error);
    }

    return issues;
  };

  const addressSearch = async (
    line_1: string,
    line_2: string,
    city: string,
    state: string,
    zip: string
  ): Promise<AddressResponse | undefined> => {
    errorsLogic.clearErrors();

    const address = formatAddress(line_1, line_2, city, state, zip);

    try {
      const { addresses, warnings } = await experianApi.searchAddress(address);

      const issues = getRelevantIssues(warnings, [portalFlow.page]);
      if (issues.length) {
        throw new ValidationError(issues);
      }

      // Even though Experian returns zero results, we will redirect user to next page using this flag
      addresses.ready_to_select_addresses = true;

      return addresses;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const addressFormat = async (
    addressKey: string
  ): Promise<AddressFormatResponse | undefined> => {
    errorsLogic.clearErrors();

    try {
      const { address, warnings } = await experianApi.formatAddress(addressKey);

      const issues = getRelevantIssues(warnings, [portalFlow.page]);
      if (issues.length) {
        throw new ValidationError(issues);
      }
      return address;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Update the claim in the API, ignoring any errors that occur
   *
   * @param patchData - subset of claim data that will be updated
   * @param claim - the current claim data from benefits application
   */
  const updateAndIgnoreErrors = async (
    application_id: string,
    patchData: Partial<BenefitsApplication>,
    claim: BenefitsApplication
  ) => {
    errorsLogic.clearErrors();

    try {
      const { claim: updatedClaim, warnings } =
        await applicationsApi.updateClaim(application_id, patchData);

      // Update the claim in the context
      setBenefitsApplication(updatedClaim);
      setClaimWarnings(application_id, warnings);

      const params = { claim_id: updatedClaim.application_id };
      portalFlow.goToNextPage({ claim: updatedClaim, warnings }, params);
    } catch (error) {
      errorsLogic.catchErrorNoShow(error);

      // Always navigate to the next page, even if an error occurs.
      const fallbackParams = { claim_id: application_id };
      portalFlow.goToNextPage({ claim }, fallbackParams);
    }
  };

  /**
   * Complete the claim in the API
   */
  const complete = async (
    applicationId: string,
    certificateDocumentDeferred: boolean = false
  ) => {
    errorsLogic.clearErrors();

    try {
      const { claim } = await applicationsApi.completeClaim(
        applicationId,
        certificateDocumentDeferred
      );

      setBenefitsApplication(claim);
      const context = { claim };
      const params = { claim_id: claim.application_id };
      portalFlow.goToNextPage(context, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Create the claim in the API. Handles errors and routing.
   */
  const create = async () => {
    errorsLogic.clearErrors();

    try {
      const { claim } = await applicationsApi.createClaim();

      let userProfileHasUsableDataForApplicationResponse;
      if (usersLogic.user) {
        try {
          userProfileHasUsableDataForApplicationResponse =
            await userProfileHasUsableDataForApplication(
              usersLogic.user.user_id
            );
        } catch (error) {
          errorsLogic.catchErrorNoShow(error);
        }
      }

      // Reset so that this newly created claim is listed
      invalidateApplicationsCache();

      const context = {
        claim,
        userProfileHasUsableDataForApplication:
          userProfileHasUsableDataForApplicationResponse,
      };
      const params = { claim_id: claim.application_id };
      portalFlow.goToPageFor("CREATE_CLAIM", context, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Submit the claim in the API and set application errors if any
   */
  const submit = async (application_id: string) => {
    errorsLogic.clearErrors();

    try {
      const { claim } = await applicationsApi.submitClaim(application_id);

      let userProfileCheckForUpdates;
      if (usersLogic.user) {
        try {
          userProfileCheckForUpdates =
            await checkUserProfileForUpdatesFromApplication(
              usersLogic.user.user_id,
              claim
            );
        } catch (error) {
          errorsLogic.catchErrorNoShow(error);
        }
      }

      setBenefitsApplication(claim);
      if (claim.split_into_application_id) {
        // Force a refetch so the second of the split applications gets displayed
        invalidateApplicationsCache();
      }

      const context = { claim, userProfileCheckForUpdates };

      const applicationWasCreatedInFineos = claim.isInManualReview
        ? { "part-one-in-review": "true" }
        : { "part-one-submitted": "true" };
      const params = {
        claim_id: claim.application_id,
        ...applicationWasCreatedInFineos,
      };
      portalFlow.goToNextPage(context, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const submitCustomerPaymentPreference = async (
    application_id: string,
    paymentPreferenceData: Partial<PaymentPreference>
  ) => {
    errorsLogic.clearErrors();

    try {
      const { claim, warnings } =
        await applicationsApi.submitCustomerPaymentPreference(
          application_id,
          paymentPreferenceData
        );

      // The user not found warnings are added to this endpoint, since the
      // next page after payment preference varies if the user is in the
      // user not found flow.
      const issues = getRelevantIssues(warnings, [portalFlow.page]);

      setBenefitsApplication(claim);
      setClaimWarnings(application_id, warnings);

      if (issues.length) {
        throw new ValidationError(issues);
      }

      const context = { claim, warnings };
      const params: NullableQueryParams = {
        claim_id: claim.application_id,
        "payment-pref-submitted": "true",
      };

      portalFlow.goToNextPage(context, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const submitTaxWithholdingPreference = async (
    application_id: string,
    data: TaxWithholdingPreference
  ) => {
    errorsLogic.clearErrors();

    try {
      const { claim, warnings } =
        await applicationsApi.submitTaxWithholdingPreference(
          application_id,
          data
        );

      setBenefitsApplication(claim);
      setClaimWarnings(application_id, warnings);

      const issues = getRelevantIssues(warnings, [portalFlow.page]);
      if (issues.length) {
        throw new ValidationError(issues);
      }

      const context = { claim, warnings };
      const params: NullableQueryParams = {
        claim_id: claim.application_id,
        "tax-pref-submitted": "true",
      };
      portalFlow.goToNextPage(context, params);
    } catch (err) {
      errorsLogic.catchError(err);
    }
  };

  const hasUserNotFoundError = (application_id: string) => {
    return (
      application_id in warningsLists &&
      warningsLists[application_id].some(
        (warning) =>
          warning.rule === "require_contributing_employer" ||
          warning.rule === "require_employee"
      )
    );
  };

  const hasNoEmployeeFoundError = (application_id: string) => {
    return (
      application_id in warningsLists &&
      warningsLists[application_id].some(
        (warning) => warning.rule === "require_employee"
      )
    );
  };

  const updateUserProfileWithApplicationSkipErrors = async (
    user_id: User["user_id"],
    patchData: UserProfileUpdate,
    claim: BenefitsApplication,
    query: NullableQueryParams | undefined = undefined
  ) => {
    try {
      await usersApi.updateUserProfile(user_id, patchData);
    } catch (error) {
      errorsLogic.catchErrorNoShow(error);
    }

    const params = { ...{ claim_id: claim.application_id }, ...(query || {}) };
    portalFlow.goToNextPage({ claim }, params);
  };

  // Cache checks for usable profile data for shared state across pages
  const [
    userProfileHasUsableDataForApplicationResponses,
    setHasUsableDataResponses,
  ] = useState<{
    [key: string]: UserProfileHasUsableDataForApplicationResponse;
  }>({});

  const setUserProfileHasUsableDataForApplicationResponse = (
    user_id: User["user_id"],
    response: UserProfileHasUsableDataForApplicationResponse
  ) => {
    setHasUsableDataResponses((prev) => {
      return {
        ...prev,
        [user_id]: response,
      };
    });
  };

  const getUserProfileHasUsableDataForApplicationResponse = (
    user_id: User["user_id"]
  ) => {
    const key = user_id;
    if (key in userProfileHasUsableDataForApplicationResponses) {
      return userProfileHasUsableDataForApplicationResponses[key];
    }
  };

  const userProfileHasUsableDataForApplication = async (
    user_id: User["user_id"]
  ) => {
    let response = getUserProfileHasUsableDataForApplicationResponse(user_id);

    if (response) {
      return response;
    }

    try {
      response = await usersApi.userProfileHasUsableDataForApplication(user_id);
    } catch (error) {
      errorsLogic.catchErrorNoShow(error);
    }

    if (!response) {
      return null;
    }

    setUserProfileHasUsableDataForApplicationResponse(user_id, response);

    return response;
  };

  // Cache checks for updates to share state across pages in the same session
  const [checkUserProfileForUpdatesResponses, setCheckForUpdateResponses] =
    useState<{
      [key: string]: UserProfileCheckForUpdatesResponse;
    }>({});

  const setCheckUserProfileForUpdateResponse = (
    user_id: string,
    claim: BenefitsApplication,
    response: UserProfileCheckForUpdatesResponse
  ) => {
    setCheckForUpdateResponses((prev) => {
      return {
        ...prev,
        [getCacheKeyForUserProfileUpdates(user_id, claim)]: response,
      };
    });
  };

  const getCheckUserProfileForUpdatesResponse = (
    user_id: string,
    claim: BenefitsApplication
  ) => {
    const key = getCacheKeyForUserProfileUpdates(user_id, claim);
    if (key in checkUserProfileForUpdatesResponses) {
      return checkUserProfileForUpdatesResponses[key];
    }
  };

  const getCacheKeyForUserProfileUpdates = (
    user_id: User["user_id"],
    claim: BenefitsApplication
  ) => {
    return user_id + JSON.stringify(claim);
  };

  const checkUserProfileForUpdatesFromApplication = async (
    user_id: User["user_id"],
    claim: BenefitsApplication
  ) => {
    let response = getCheckUserProfileForUpdatesResponse(user_id, claim);

    if (response) {
      return response;
    }

    try {
      response = await usersApi.userProfileCheckForUpdates(
        user_id,
        new UserProfileCheckForUpdatesRequest({
          from_application: claim.application_id,
        })
      );
    } catch (error) {
      errorsLogic.catchErrorNoShow(error);
    }

    if (!response) {
      return null;
    }

    setCheckUserProfileForUpdateResponse(user_id, claim, response);

    return response;
  };

  return {
    addressSearch,
    addressFormat,
    benefitsApplications,
    checkUserProfileForUpdatesFromApplication,
    getCheckUserProfileForUpdatesResponse,
    complete,
    create,
    hasLoadedBenefitsApplicationAndWarnings,
    invalidateApplicationsCache,
    isLoadingClaims,
    load,
    loadPage,
    paginationMeta,
    update,
    updateAndIgnoreErrors,
    submit,
    submitCustomerPaymentPreference,
    submitTaxWithholdingPreference,
    hasUserNotFoundError,
    hasNoEmployeeFoundError,
    warningsLists,
    updateUserProfileWithApplicationSkipErrors,
    userProfileHasUsableDataForApplication,
    getUserProfileHasUsableDataForApplicationResponse,
    validateAddressFields,
  };
};

export default useBenefitsApplicationsLogic;
