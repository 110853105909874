import BaseApi from "./BaseApi";
import EmployerExemptionsApplication from "src/models/EmployerExemptionsApplication";
import routes from "src/routes";

export default class EmployerExemtionsApi extends BaseApi {
  get basePath() {
    return routes.api.employerExemptionsApplications;
  }

  get namespace() {
    return "employerExemptions";
  }

  get headers() {
    return [
      { featureFlag: "enableEmployerExemptionsPortal" },
      {
        name: "X-FF-Enable-Employer-Exemptions-Portal",
        value: true,
      },
    ];
  }

  getExemptionApplication = async (
    employer_exemption_application_id: string
  ) => {
    try {
      const { data, warnings } =
        await this.request<EmployerExemptionsApplication>(
          "GET",
          employer_exemption_application_id
        );
      return {
        exemptionRequest: new EmployerExemptionsApplication(data),
        warnings,
      };
      // TODO (PFMLPB-20599): remove once backend API is complete
    } catch (error) {
      return {
        exemptionRequest: new EmployerExemptionsApplication({
          employer_exemption_application_id,
        }),
        warnings: [],
      };
    }
  };

  updateExemptionApplication = async (
    employer_exemption_application_id: string,
    patchData: Partial<EmployerExemptionsApplication>
  ) => {
    try {
      const { data, warnings } =
        await this.request<EmployerExemptionsApplication>(
          "PATCH",
          employer_exemption_application_id,
          patchData
        );

      // TODO (PFMLPB-20599): return data once backend API is complete

      return {
        exemptionRequest: new EmployerExemptionsApplication(data),
        warnings,
      };
    } catch (error) {
      // TODO (PFMLPB-20599): remove once backend API is complete
      return {
        exemptionRequest: new EmployerExemptionsApplication({
          employer_exemption_application_id,
          ...patchData,
        }),
        warnings: [],
      };
    }
  };
}
