import {
  InsuranceProvider,
  InsuranceProviderPlans,
} from "src/models/InsuranceProvider";
import React, { useEffect, useMemo, useState } from "react";
import withUser, { WithUserProps } from "src/hoc/withUser";

import ConditionalContent from "src/components/ConditionalContent";
import Dropdown from "src/components/core/Dropdown";
import ExternalLink from "src/components/core/ExternalLink";
import Fieldset from "src/components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import InputDate from "src/components/core/InputDate";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "src/routes";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

// TODO (PFMLPB-20787): update this export with all fields being edited in this file
export const fields = [
  "exemptionRequest.is_self_insured_plan",
  "exemptionRequest.has_family_exemption",
  "exemptionRequest.has_medical_exemption",
  "exemptionsRequest.purchased_plan.insurance_provider_id",
  "exemptionsRequest.purchased_plan.insurance_plan_id",
  "exemptionRequest.insurance_plan_effective_at",
];

export const EmployerExemptionsInsuranceDetails = (props: WithUserProps) => {
  const { appLogic, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;
  const { formState, updateFields } = useFormState({
    is_self_insured_plan: undefined,
    exemption_type: "",
    has_family_exemption: undefined,
    has_medical_exemption: undefined,
    insurance_provider_id: undefined,
    insurance_plan_id: undefined,
    insurance_plan_effective_at: undefined,
  });
  const [insuranceProviders, updateInsuranceProviders] = useState<
    InsuranceProvider[]
  >([]);
  const [insuranceProviderPlans, updateInsuranceProviderPlans] = useState<
    InsuranceProviderPlans[]
  >([]);
  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const ExemptionType = {
    MEDICAL: "medical",
    FAMILY: "family",
    BOTH: "both",
  } as const;
  // TODO (PFMLPB-21719): update this list to be retrieving the insurance providers from BE
  useEffect(() => {
    updateInsuranceProviders([
      {
        insurance_provider_id: 2,
        insurance_provider_name: "Placeholder provider two",
        address: {
          city: "Springfield",
          line_1: "742 Evergreen Terrace",
          line_2: "",
          state: "MA",
          zip: "12345",
        },
        phone: {
          int_code: "1",
          phone_number: "224-705-2345",
          extension: "123",
          phone_type: "Cell",
        },
      },
    ]);
  }, []);

  const insuranceProviderChoices = useMemo(
    () =>
      insuranceProviders.map((provider) => {
        return {
          label: provider.insurance_provider_name,
          value: provider.insurance_provider_id,
        };
      }),
    [insuranceProviders]
  );
  // TODO (PFMLPB-21720): update this list to be retrieving the insurance providers from BE
  useEffect(() => {
    updateInsuranceProviderPlans([
      {
        insurance_plan_id: 0,
        insurance_provider_id: 0,
        form_name: "Plan name",
        has_family_exemption: true,
        has_medical_exemption: true,
      },
    ]);
  }, [formState.insurance_provider_id]);

  const insuranceProviderPlanChoices = useMemo(
    () =>
      insuranceProviderPlans.map((plan) => {
        return {
          label: plan.form_name,
          value: plan.insurance_plan_id,
        };
      }),
    [insuranceProviderPlans]
  );
  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  // TODO (PFMLPB-20787): update to use hook
  const handleSave = () => Promise.resolve();

  const handleLeaveType = () => {
    if (formState.exemption_type === ExemptionType.BOTH) {
      updateFields({ has_family_exemption: true, has_medical_exemption: true });
    } else if (formState.exemption_type === ExemptionType.MEDICAL) {
      updateFields({
        has_family_exemption: false,
        has_medical_exemption: true,
      });
    } else {
      updateFields({
        has_family_exemption: true,
        has_medical_exemption: false,
      });
    }
    // Promise.resolve()
  };

  const getTypeOfExemptionPlan = () => {
    const getQuestion = () => {
      return (
        <Fieldset className="margin-top-0">
          <InputChoiceGroup
            {...getFunctionalInputProps("exemption_type", {
              fallbackValue: null,
            })}
            type="radio"
            label={t(
              "pages.employersExemptionsInsuranceDetails.questionTypeOfLeave"
            )}
            smallLabel
            choices={[
              {
                value: ExemptionType.MEDICAL,
                label: t(
                  "pages.employersExemptionsInsuranceDetails.questionTypeOfLeaveOption_Medical"
                ),
                // TODO (PFMLPB-21929): add check to show sleceted feild based on the application form
                // checked: { !formState.has_family_exemption && formState.has_medical_exemption },
              },
              {
                value: ExemptionType.FAMILY,
                label: t(
                  "pages.employersExemptionsInsuranceDetails.questionTypeOfLeaveOption_Family"
                ),
                // TODO (PFMLPB-21929): add check to show sleceted feild based on the application form
                // checked: { formState.has_family_exemption && !formState.has_medical_exemption },
              },
              {
                value: ExemptionType.BOTH,
                label: t(
                  "pages.employersExemptionsInsuranceDetails.questionTypeOfLeaveOption_Both"
                ),
                // TODO (PFMLPB-21929): add check to show sleceted feild based on the application form
                // checked: { formState.has_family_exemption && formState.has_medical_exemption },
              },
            ]}
            onChange={() => handleLeaveType()}
          />
        </Fieldset>
      );
    };

    return (
      <ConditionalContent visible={formState.is_self_insured_plan != null}>
        {getQuestion()}
      </ConditionalContent>
    );
  };
  // TODO (PFMLPB-21929) seperate this section into new file
  const privatePlanDetailQuestions = () => {
    return (
      <ConditionalContent visible={formState.is_self_insured_plan === false}>
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans i18nKey="pages.employersExemptionsInsuranceDetails.purchasedSectionLabelHint" />
            }
          >
            {t("")}
          </FormLabel>
          {getPrivatePlanProvider()}
          <FormLabel
            small
            component="legend"
            hint={
              <Trans
                i18nKey="pages.employersExemptionsInsuranceDetails.puchasedSectionPlanNumber_hint"
                components={{
                  "pfml-confirmation-of-insurance-link": (
                    <ExternalLink
                      href={
                        routes.external.massgov
                          .purchasedPrivatePlanConfirmationForm
                      }
                    />
                  ),
                }}
              />
            }
          >
            {t(
              "pages.employersExemptionsInsuranceDetails.puchasedSectionPlanNumber_label"
            )}
          </FormLabel>
          {getPrivatePlanFromProvider()}
          <FormLabel
            small
            component="legend"
            hint={
              <Trans i18nKey="pages.employersExemptionsInsuranceDetails.puchasedSectionEffectiveDates_hint" />
            }
          >
            {t(
              "pages.employersExemptionsInsuranceDetails.puchasedSectionEffectiveDates_label"
            )}
          </FormLabel>
          {getPlanEffectiveDate()}
        </Fieldset>
      </ConditionalContent>
    );
  };

  const getPrivatePlanProvider = () => {
    return (
      <Dropdown
        choices={insuranceProviderChoices}
        {...getFunctionalInputProps("insurance_provider_id")}
        emptyChoiceLabel={t(
          "pages.employersExemptionsInsuranceDetails.dropdownDefault"
        )}
        label={t(
          "pages.employersExemptionsInsuranceDetails.questionPlanProvider"
        )}
        smallLabel
        name="provider_id"
        value={formState.insurance_provider_id}
        onChange={(event) =>
          updateFields({ insurance_provider_id: event.target.value })
        }
      />
    );
  };

  const getPrivatePlanFromProvider = () => {
    return (
      <Dropdown
        {...getFunctionalInputProps("insurance_plan_id")}
        choices={insuranceProviderPlanChoices}
        emptyChoiceLabel={t(
          "pages.employersExemptionsInsuranceDetails.dropdownDefault"
        )}
        label={t(
          "pages.employersExemptionsInsuranceDetails.questionPlanFromProvider"
        )}
        smallLabel
        name="provider_id"
        value={formState.insurance_plan_id}
        onChange={(event) =>
          updateFields({ insurance_plan_id: event.target.value })
        }
      />
    );
  };

  const getPlanEffectiveDate = () => {
    return (
      <InputDate
        {...getFunctionalInputProps("insurance_plan_effective_at")}
        name={"insurance_plan_effective_at"}
        smallLabel
        label={t(
          "pages.employersExemptionsInsuranceDetails.questionPlanEfectiveDate_label"
        )}
        dayLabel={t("components.form.dateInputDayLabel")}
        monthLabel={t("components.form.dateInputMonthLabel")}
        yearLabel={t("components.form.dateInputYearLabel")}
        value={formState.insurance_plan_effective_at}
        example={t("components.form.dateInputExample")}
      />
    );
  };

  // End TODO (PFMLPB-21929) removal

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsInsuranceDetails.title")}
        alertBanner={
          <div>
            <Trans
              i18nKey="shared.employerExemptions.employerEIN"
              values={{
                employer_fein: user_leave_administrators[0].employer_fein,
              }}
            />
            <br />
            <br />
          </div>
        }
        disableSubmit={true}
        onSave={handleSave}
      >
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans
                i18nKey="pages.employersExemptionsInsuranceDetails.sectionLabelHint"
                components={{
                  "benefit-plan-requirements-link": (
                    <ExternalLink
                      href={
                        routes.external.massgov
                          .benefitsGuide_privatePaidLeavePlanExemptions
                      }
                    />
                  ),
                }}
              />
            }
          >
            {t("pages.employersExemptionsInsuranceDetails.sectionLabel")}
          </FormLabel>
          <InputChoiceGroup
            {...getFunctionalInputProps("is_self_insured_plan")}
            type="radio"
            smallLabel
            label={t(
              "pages.employersExemptionsInsuranceDetails.radioSelectHeaderPlanType"
            )}
            choices={[
              {
                value: "false",
                label: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionPurchased"
                ),
                hint: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionPurchasedHint"
                ),
              },
              {
                value: "true",
                label: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionSelfInsured"
                ),
                hint: t(
                  "pages.employersExemptionsInsuranceDetails.radioSelectOptionSelfInsuredHint"
                ),
              },
            ]}
          />
        </Fieldset>

        {getTypeOfExemptionPlan()}

        {privatePlanDetailQuestions()}
      </QuestionPage>
    </React.Fragment>
  );
};

// TODO (PFMLPB-20787): update this to wrap with employer exemptions hoc
export default withUser(EmployerExemptionsInsuranceDetails);
