import { PhoneWithExtension } from "./Phone";
import { merge } from "lodash";
class EmployerExemptionsApplication {
  employer_exemption_application_id: string; // starts null auto populated by backend
  contact_first_name: string | null = null;
  contact_last_name: string | null = null;
  contact_title: string | null = null;
  contact_phone_id: PhoneWithExtension;
  contact_email_address: string | null = null;
  are_employer_contributions_required: boolean | null = null;
  should_workforce_count_include_1099_misc: boolean | null = null;
  average_workforce_count: number | null = null;
  has_family_exemption: boolean | null = null;
  has_medical_exemption: boolean | null = null;
  is_self_insured_plan: boolean | null = null; // this value determines if purchased plan block is validated or if self_insured block is validated
  insurance_plan_effective_at: Date | null = null;
  insurance_plan_expires_at: Date | null = null;
  purchased_plan: {
    insurance_provider_id: string | null;
    insurance_plan_id: string | null;
  };

  self_insured: {
    has_obtained_surety_bond: boolean | null;

    surety_company: string | null;

    surety_bond_effective_date: Date | null;

    surety_bond_amount: number | null;

    has_third_party_administrator: boolean | null;

    tpa_business_name: string | null;

    tpa_contact_name: string | null;

    tpa_phone_number_id: PhoneWithExtension | null;
    tpa_email_address: string | null;

    questions: {
      does_plan_cover_all_employees: boolean | null;
      does_plan_provide_enough_leave: boolean | null;
      does_plan_provide_enough_medical_leave: boolean | null;
      does_plan_provide_enough_caring_leave: boolean | null;
      does_plan_provide_enough_childcare_leave: boolean | null;
      does_plan_provide_enough_bonding_leave: boolean | null;
      does_plan_provide_enough_armed_forces_leave: boolean | null;
      does_plan_provide_enough_armed_forces_illness_leave: boolean | null;
      does_plan_pay_enough_benefits: boolean | null;
      does_employer_withhold_premiums: boolean | null;
      are_employer_withholdings_within_allowable_amount: boolean | null;
      does_plan_provide_pfml_job_protection: boolean | null;
      does_plan_provide_return_to_work_benefits: boolean | null;
      does_plan_cover_employee_contribution: boolean | null;
      does_plan_cover_short_term_disability: boolean | null;
      does_plan_provide_intermittent_caring_leave: boolean | null;
      does_plan_provide_intermittent_bonding_leave: boolean | null;
      does_plan_provide_intermittent_armed_forces_leave: boolean | null;
      does_plan_provide_intermittent_medical_leave: boolean | null;
      does_plan_cover_former_employees: boolean | null;
      does_plan_favor_paid_leave_benefits: boolean | null;
    };
  };

  documents: {
    //   TBD-A completed Massachusetts Paid Family and Medical Leave Confirmation of Insurance Policy Form Number
    //   TBD-A completed Self-Insured Insurance Declaration document
    //   TBD-Copy of your signed and notarized Surety Bond
    //   TBD-Copy of your Policy Document
  };

  employer_exemption_application_status_id: number | null = null;
  is_legally_acknowledged: boolean;
  submitted_at: Date | null = null; // dateTime stamp
  created_by_user_id: string | null = null;
  employer_id: string;
  is_application_status_auto_decided: boolean | null = null;
  submitted_by_user_id: string | null = null;

  /**
   * Check if Claim is started (before being submitted or completed).
   */
  get isStarted() {
    return this.employer_exemption_application_status_id !== null; // Once statuses are determined this will be updated to check that it is in "draft"
  }

  /**
   * Check if Claim has been submitted yet. This affects the editability
   * of some fields, and as a result, the user experience.
   */
  get isSubmitted() {
    // The API calculates this by checking if a claim exists
    return this.employer_exemption_application_status_id !== 1; // this need to be NOT in draft
  }

  /**
   * check to see if the user has acknowleged legal disclamer
   */
  get isLegallyAcknowledged() {
    return this.is_legally_acknowledged;
  }

  constructor(attrs: Partial<EmployerExemptionsApplication> = {}) {
    merge(this, attrs);
  }
}

export default EmployerExemptionsApplication;
